import { Link } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import ProjectPreview from "./project-preview"
import VideoPlayerPopover from "./video-popover";

import * as styles from "./project-preview-grid.module.css";

function ProjectPreviewGrid(props) {

  const [previewing, setPreviewing] = useState(false, 0);
  const [video, setVideo] = useState("");

  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node, i) => (

            <li key={node.id}>
                <ProjectPreview {...node} i={i} previewing={previewing} setPreviewing={setPreviewing} setVideo={setVideo}/>
            </li>
          ))}
      </ul>
      <VideoPlayerPopover video={video} setVideo={setVideo}/>
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default ProjectPreviewGrid;
