import { Link } from "gatsby";
import React, { useState, useEffect }  from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import { motion, AnimatePresence } from "framer-motion"

import * as styles from "./project-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";


function ProjectPreview(props) {
  const isPreviewing = props.i === props.previewing;

  const video = props.video
  const setVideo = props.setVideo


  return (
    <motion.div
      onHoverStart={() => props.setPreviewing(isPreviewing ? false : props.i)}
      initial={false}
      animate={{ opacity: isPreviewing ? 1 : 0.8 }}
      className={styles.root}
      onClick={() => setVideo(String(props.vimeoURL))}
    >
      {props.mainImage && props.mainImage.asset && (
        <motion.img 
          className={styles.leadMediaThumb}
          animate={{ scale: isPreviewing ? 1.05 : 1 }}
          src={imageUrlFor(props.mainImage)
            .url()}
          alt={props.mainImage.alt}
        />
      )}
        <motion.div 
          className={styles.text}
          initial="hidden"
          animate={{ scale: isPreviewing ? 1.05 : 1 }}
          exit="hidden"
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 1 }
          }}
        >           
          <div
            className={styles.link}
            onFocus={() => props.setPreviewing(isPreviewing ? false : props.i)} 
          >
            <h2 className={styles.title}>
              {props.title}
            </h2>
          </div>
        </motion.div>
      </motion.div>
  );
}

export default ProjectPreview;
