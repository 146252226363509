import { Link } from "gatsby";
import React from "react";

import { motion, useMotionValue } from "framer-motion";

import * as styles from "./video-popover.module.css";

function VideoPlayerPopover(props) {

  const video = props.video
  const setVideo = props.setVideo
  
  const variants = {
    active: {
      scale: 1,
      opacity: 1
    },
    inactive: {
      scale: 0,
      opacity: 0
    }
  }

  return (
    <motion.div className={styles.root} variants={variants} initial="inactive" animate={video != "" ? "active" : "inactive" } onClick={() => setVideo("")}>
      <video key={video} className={styles.video} autoplay="true">
        <source src={video}
            type="video/mp4"></source>
      </video>

    </motion.div>
  );
}


export default VideoPlayerPopover;
