import { Link } from "gatsby";
import React from "react";
import { imageUrlFor } from "../lib/image-url";
import SEO from "../components/seo";
import Logo from "../components/logo";

import * as styles from "./splash.module.css";

const Splash = () => {



  return (
    <div className={styles.root}>
            <SEO title="Home" />
        <img className={styles.logo} src="/topnotch.png"></img>
        <Link className={styles.link} to="/services">Enter</Link>
    </div>
  );
};

export default Splash;
